import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import parse from 'html-react-parser'

import GestionDTE from '../../../administracion/jsons_images/Productos/GestionDTE/GestionDTE'

export default function Header() {
  return (
    <Grid
      bgImage={{
        md: GestionDTE.bg_w,
        base: GestionDTE.bg_m,
      }}
      bgSize="cover"
      // maxHeight='700px'
      // minHeight='700px'
      backgroundRepeat="no-repeat"
      pl={{ md: '10%', base: '7%' }}
      pt={{ base: '500px', md: '200px' }}
      pb={{ base: '50px', md: '200px' }}
    >
      <GridItem display="inline" float="center">
        <Text fontSize="30px" fontWeight={'bold'} pl="30px" as={'mark'} bgColor="#B2D237">
          {GestionDTE.titulo1}{' '}
        </Text>
        <Text as="span" fontSize="30px" fontWeight={'bold'}>
          {GestionDTE.titulo2}
        </Text>
        <Text fontSize="30px" fontWeight={'bold'} pl="30px">
          {GestionDTE.titulo3}
        </Text>
        <Text fontSize="30px" fontWeight={'bold'} pl="30px">
          {GestionDTE.titulo4}
        </Text>
      </GridItem>
      <GridItem w={{ lg: '50%', base: '80%' }} pl="30px">
        <Text fontSize="12px">{parse(GestionDTE.subtitulo)}</Text>
      </GridItem>
    </Grid>
  )
}
