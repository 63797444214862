import React from 'react'
import { Grid } from '@chakra-ui/react'

// import ModulosEspeciales from '../../components/Productos/ERP/ModulosEspeciales'
import Modulos from '../../components/Productos/GestionDTE/Modulos'
import Header from '../../components/Productos/GestionDTE/Header'
// import Contactanos from '../../components/Shared/Contactanos'

export default function GestionDTE() {
  return (
    <Grid>
      <Header />
      <Modulos />
      {/* <ModulosEspeciales /> */}
      {/* <Contactanos /> */}
    </Grid>
  )
}
