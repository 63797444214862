import React from 'react'
import { Box, Button, Image } from '@chakra-ui/react'

import contacto from '../../administracion/jsons_images/Shared/Contacto/contacto'

export default function Whatsapp() {
  const openWhatsapp = () => {
    window.open(`https://wa.me/${contacto.telefono}`)
  }

  return (
    <Box
      position="fixed" // Fixes the position relative to the viewport
      bottom="50px" // Position near the bottom
      right="30px" // Position at the far right
      width="100%" // Spans full width
      zIndex="10" // Ensures it's above other elements
    >
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={openWhatsapp} bg="white" p="0" borderRadius="full">
          <Image src={contacto.whatsapp.logo} alt="WhatsApp Logo" boxSize="75px" />
        </Button>
      </Box>
    </Box>
  )
}
