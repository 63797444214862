import bg_m from './gestionDTE_m_bg.png'
import bg_w from './gestionDTE_w_bg.png'

import mod01 from './Modulos/mod01.svg'
import mod02 from './Modulos/mod02.svg'
import mod03 from './Modulos/mod03.svg'


const GestionDTE = {
    bg_w: bg_w,
    bg_m: bg_m,
    titulo1: 'Gestión DTE',
    titulo2: '',
    titulo3: 'Gestión Tributaria Inteligente',
    subtitulo:
        'Gestión DTE de Audisoft es la solución integral para <b>emitir, recibir y gestionar documentos tributarios electrónicos</b>. Con soporte multiempresa, usuarios, informes, integración con Acontadores y más, optimiza tus procesos tributarios con seguridad y eficiencia.',
    modulos: {
        titulo1: 'Algunos de sus',
        titulo2: 'módulos',
        contenido: [
            {
                titulo: 'Gestión DTE: Simplifica y optimiza la emisión de documentos tributarios electrónicos',
                texto:
                    'En Audisoft, entendemos la importancia de un manejo eficiente y seguro de los documentos tributarios electrónicos (DTE). Nuestro producto Gestión DTE está diseñado para adaptarse a las necesidades de empresas de diferentes tamaños y rubros, ofreciendo una plataforma completa y fácil de usar que centraliza todas las funciones necesarias para la emisión, recepción y gestión de tus DTE. Con características como el envío ilimitado de documentos, captura automática de timbres y la posibilidad de integración con otros sistemas, puedes confiar en que tus procesos tributarios estarán siempre bajo control.',
                imagen: mod01,
            },
            {
                titulo: 'Flexibilidad y control en cada paso',
                texto:
                    'Con Gestión DTE, puedes gestionar múltiples empresas desde una misma plataforma, lo que lo hace ideal para grupos empresariales o consultoras. Además, permite definir roles y permisos para múltiples usuarios, asegurando un acceso seguro y controlado según las funciones de cada integrante de tu equipo. La integración directa con el sistema contable Acontadores y la conciliación del registro de compras y ventas del SII garantizan una conexión fluida entre tus procesos tributarios y contables, ahorrando tiempo y evitando errores.',
                imagen: mod02,
            },
            {
                titulo: 'Herramientas avanzadas para maximizar tu eficiencia',
                texto:
                    'Nuestro sistema incluye funcionalidades clave como informes detallados de compras y ventas, generación de archivos de cesión de documentos, y la consulta y reenvío de documentos al SII y a tus clientes. Además, la recepción y acuse de recibo de documentos recibidos te ayuda a mantener un seguimiento preciso de tus transacciones. Todo esto, combinado con la posibilidad de enviar notificaciones a múltiples correos electrónicos y la integración con otros sistemas, hace de Gestión DTE una solución integral para modernizar y optimizar la gestión tributaria de tu empresa.',
                imagen: mod03,
            },

        ],
    },
}

export default GestionDTE
