import React, { useState } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl' // ONLY USE v5.2.11
import { IconButton } from '@chakra-ui/react'
import { FaMapMarkerAlt } from 'react-icons/fa'

const token = process.env.REACT_APP_MAPGL_API_KEY

export default function Map() {
  const [viewport] = useState({
    latitude: -40.576169,
    longitude: -73.130736,
    zoom: 16,
    width: '100%',
    height: '70vh',
    size: 20,
  })

  function Pin() {
    return (
      <IconButton
        isRound="true"
        backgroundColor="#BEE625"
        icon={<FaMapMarkerAlt color="#262626" />}
      ></IconButton>
    )
  }

  return (
    // <ReactMapGL
    //   mapboxApiAccessToken={token}
    //   {...viewport}
    //   mapStyle={'mapbox://styles/mapbox/light-v10'}
    //   // onViewportChange={(viewport) => setViewport(viewport)}
    //   scrollZoom={false}
    // >
    //   <Marker key={`marker-${1}`} latitude={viewport.latitude} longitude={viewport.longitude}>
    //     <Pin />
    //   </Marker>
    // </ReactMapGL>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d637.0702813826865!2d-73.13082134405666!3d-40.57617370601501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x961636616126ba67%3A0x25cb49a55a52f74b!2sAudisoft!5e0!3m2!1ses-419!2scl!4v1734706462412!5m2!1ses-419!2scl"
      // width="600"
      width={viewport.width}
      height={'400px'}
      // style="border:0;"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
      title="mapa"
    ></iframe>
  )
}
